import { useEffect, useState } from 'react';

import { ProtectedContent, PublicContent } from './content';
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme, Provider } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';

export default function App(){
  const [appAppearance, setAppAppearance] = useState(teamsDarkTheme);
  const [teamId, setTeamId] = useState("default")

  useEffect(() => {
    microsoftTeams.getContext((context) => {
      setAppAppearance(initTeamsTheme(context.theme));
      setTeamId(context.teamId);

      microsoftTeams.appInitialization.notifySuccess();
    });

    microsoftTeams.registerOnThemeChangeHandler((theme) => {
      setAppAppearance(initTeamsTheme(theme));
    });
  }, []);

  return (
    <Provider theme={appAppearance}>
      {
        (teamId === process.env.REACT_APP_TEAMID_GENERALI || teamId ===  process.env.REACT_APP_TEAMID_CLOUDIFAI) ? <ProtectedContent /> : <PublicContent teamId={teamId}/>
      }
    </Provider>
  );
}

function initTeamsTheme(theme) {
  switch (theme) {
    case "dark":
      return teamsDarkTheme;
    case "contrast":
      return teamsHighContrastTheme;
    default:
      return teamsTheme;
  }
}
