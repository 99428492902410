import axios from 'axios'

class Http {
  constructor() {
    axios.defaults.baseURL = '/api'
    axios.defaults.withCredentials = true
  }

  request(method, url, data, params) {
    return axios.request({
      url,
      data,
      params: params,
      method: method.toLowerCase()
    })
  }

  get(url, params) {
    return this.request('get', url, null, params)
  }

  post(url, data, params) {
    return this.request('post', url, data, params)
  }

  put(url, data, params) {
    return this.request('put', url, data, params)
  }
}

export default new Http()
