import React from 'react';
import { Breadcrumb, ChevronEndIcon, Flex, FlexItem, Header, Text } from '@fluentui/react-northstar'

export class DocumentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item
    }
  }

  truncate(str, maxLength, separator = '...') {
    if (str.length <= maxLength) {
      return str;
    }

    const charsToShow = maxLength - separator.length;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return str.substr(0, frontChars) + separator + str.substr(str.length - backChars);
  }

  render() {
    return (
      <Flex design={{ marginBottom: '0' }} padding="padding.medium" styles={{
        borderBottom: '1px solid rgba(255, 255, 255, .2)',
        ':hover': {
          background: 'rgba(255, 255, 255, .05)'
        }
      }}>
        <FlexItem grow>
          <Flex column>
            <Flex space="between">
              <Breadcrumb size="small">
                <Breadcrumb.Item active design={{ paddingLeft: '0' }}>{this.state.item.area}</Breadcrumb.Item>
                <Breadcrumb.Divider>
                    <ChevronEndIcon size="smaller" />
                </Breadcrumb.Divider>
                <Breadcrumb.Item active>{this.state.item.system}</Breadcrumb.Item>
              </Breadcrumb>
              <Header as="h5" content={this.truncate(this.state.item.file, 48)} design={{ margin: '0' }} title={this.state.item.file} />
            </Flex>
            <Flex space="between">
              <Text as="a" href={this.state.item.link} target="_blank" content={this.state.item.content} styles={{textDecoration: "none", fontWeight: "bold"}} design={{ margin: '0' }} title="Open in SharePoint" color="brand" />
              <Text size="small" content={'Location: ' + this.state.item.location} />
            </Flex>
            <Flex>
              <Text size="small" weight="semibold" content="Section:" design={{ margin: '0' }} />
              <Text size="small" content={this.state.item.structure.join(', ')} design={{ margin: '0', marginLeft: '.5em' }} />
            </Flex>
            <Flex>
              <Text size="small" weight="semibold" content="Topic:" design={{ margin: '0' }} />
              <Text size="small" content={this.state.item.topic.join(', ')} design={{ margin: '0', marginLeft: '.5em' }} />
            </Flex>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}

export function DocumentCards (props) {
  return (
    <div>
      {props.items.map(item => (
        <DocumentCard key={item._id} item={item} />
      ))}
    </div>
  )
}
