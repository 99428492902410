import { useEffect } from 'react';
import { Dropdown } from '@fluentui/react-northstar';

export default function DropdownFilter(props) {

  const handleFilterChange = (e, selectedOption) => {
    props.updateFilter(props.name, selectedOption.value);
  }

  return (
    <Dropdown
        inline={props.inline}
        search
        clearable
        fluid
        items={props.items[props.name] || []}
        placeholder={props.placeholder}
        noResultsMessage="We couldn't find any matches."
        onChange={handleFilterChange}
    />
  );
}
