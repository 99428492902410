import { useEffect, useState } from 'react';
import { Flex, Grid, Segment, Text } from '@fluentui/react-northstar';

import DropdownFilter from './filters';
import { DocumentCards } from './documents';
import http from './utils/http';

export function ProtectedContent(){
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [filterValues, setFilterValues] = useState({});

  const handleFilterUpdate = (name, value) => {
    let newFilters = filters;
    if (value == null){
      delete newFilters[name];
      setFilters(newFilters);
    } else {
      newFilters[name] = value;
      setFilters(newFilters);
    }
    fetchData()
  };

  const fetchData = async () => {
    await fetchDocuments();
    await fetchFiltersOptions();
  }

  const fetchDocuments = async () => {
    let res;
    if (Object.keys(filters).length === 0){
      res = await http.get('/documents');
    } else {
      res = await http.post('/documents', filters);
    }
    setItems(res.data);
  };

  const fetchFiltersOptions = async () => {
    console.log(filters);
    const res = await http.post(`/fields/values`, filters);
    setFilterValues(res.data);
  };

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <Grid columns="repeat(4, 1fr)">
        <Segment
          content={
            <Flex gap="gap.small" padding="padding.medium" space="between">
              <DropdownFilter name="content" items={filterValues} placeholder="Search content" updateFilter={handleFilterUpdate}/>
              <Flex>
                <DropdownFilter name="area" items={filterValues} placeholder="Area" inline updateFilter={handleFilterUpdate} />
                <DropdownFilter name="system" items={filterValues} placeholder="System" inline updateFilter={handleFilterUpdate}/>
                <DropdownFilter name="topic" items={filterValues} placeholder="Topic" inline updateFilter={handleFilterUpdate}/>
                <DropdownFilter name="structure" items={filterValues} placeholder="Section" inline updateFilter={handleFilterUpdate}/>
              </Flex>

            </Flex>
          }
          styles={{
            gridColumn: '1 / 5',
            gridRow: '1',
            position: 'fixed',
            top: '0',
            width: '100%',
            zIndex: '9999'
          }}
        />
        <Segment
          content={
            <DocumentCards items={items} />
          }
          styles={{
            gridColumn: '1 / 5',
            gridRow: '2 / 5',
            paddingTop: '72px'
          }}
        />
      </Grid>
  )
}

export function PublicContent (props){
  return (
    <Text content={"Content not available for teamId: " + props.teamId}></Text>
  )
}
